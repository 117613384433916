<template>
  <v-main>
    <AppBar 
      :buttons="buttons" 
      back="/org/create/step2"
      @onSave="onSave"
    ></AppBar>
    <Loading v-model="loading" />

    <v-container>

      <v-row>
        <v-col>
          <h3>Setup new account</h3>
          <div class="subheading">Step 3 of 3</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-alert v-if="success" type="success" dismissible>{{success}}</v-alert>
          <v-alert v-if="error" type="error" dismissible>{{error}}</v-alert>
        </v-col>
      </v-row>

      <user-profile />

      <v-row class="mt-7">
        <v-col>
          <h3 class="text-left">Staff Added</h3>
        </v-col>
      </v-row>


      <v-row>
        <v-col>
          <v-data-table
            class="elevation-2"
            :items="users"
            :headers="headers"
          >
            <template v-slot:[`item.role`]="{ item }">
              {{roleLabel(item.role)}}
            </template>

            <template v-slot:[`item.locations`]="{ item }">
              {{item.role == 'primary' ? locations.length : item.locations.length}}
            </template>

            <template v-slot:[`item.all_access`]="{ item }">
              <v-switch
                v-model="item.all_access"
                :readonly="item.role == 'primary' ? true : false"
              ></v-switch>
            </template>

            <template v-slot:[`item.id`]="{ item }">
              <div v-if="item.role != 'primary'">
              
              <v-btn small color="error"  class="mr-2" @click="removeStaff(item)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>                    
              </div>
            </template>
          
          </v-data-table>
        </v-col>
      </v-row>



    </v-container>

  </v-main>
</template>
<script>
import AppBar from '@/components/general/AppBar.vue'
import Loading from '@/components/Loading.vue'
import UserProfile from './UserProfile.vue'

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      formData = buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
  return formData
}

export default {
  components: {
    AppBar,
    Loading,
    UserProfile,
  },
  data: () => ({
    loading: false,
    success: null,
    error: null,
    buttons: [
      {label: 'SAVE ACCOUNT', click: 'onSave', color: 'primary', right: true, hidden: false}
    ],
    headers: [
      {text: 'First Name', value: 'first_name'},
      {text: 'Last Name', value: 'last_name'},
      {text: 'Last Email', value: 'email'},
      {text: 'Role', value: 'role'},
      {text: 'Locations', value: 'locations'},
      {text: 'Auto-Add', value: 'all_access'},
      {text: 'Actions', value: 'id'},
    ]
  }),
  computed: {
    users() {
      const users = this.$store.state.org.org.users.slice()
      users.unshift(this.$store.state.org.org.superadmin)
      return users
    },
    locations() {
      return this.$store.state.org.org.locations
    }
  },
  methods: {
    roleLabel(role) {
      switch(role) {
        case 'primary':
          return 'Super Administrator (Primary)'
        case 'SUPERADMIN':
          return 'Super Administrator'
        case 'ADMIN':
          return 'Administrator'
        case 'MANAGER':
          return 'Manager'
        case 'STAFF':
          return 'Staff'
      }
    },
    removeStaff(user) {
      this.$store.commit('removeStaff', user)
    },
    onSave() {
      let formData = new FormData()
      const org = Object.assign({}, this.$store.state.org.org)
      const files = org.files
      delete org.files
      console.log(files)
      console.log(org)
      let i = 0
      this.$store.state.org.org.files.forEach(file=>{
        formData.append('files'+i, file);
        i++
      })
      
      // formData.append('files', this.$store.state.org.org.files[0])
      // formData.append('org', org)
      formData.append('files_count', i)
      formData = buildFormData(formData, org, 'org')
      console.log(formData)
      this.loading = true
      this.error = null
      this.success = null
      this.$api.org.create(formData)
        .then(() => {
          this.$router.push('/accounts?success')
        })
        .catch((msg) => {
          this.error = msg
        })
        .finally(() => {
          this.loading = false
        })

    }
  }
}
</script>