<template>
  <v-main>
    <AppBar 
      :buttons="buttons" 
      back="/org/create/step1"
    ></AppBar>
    <Loading v-model="loading" />
    <v-container v-if="!loading">

      <v-row>
        <v-col>
          <v-alert v-if="success" type="success" dismissible>{{success}}</v-alert>
          <v-alert v-if="error" type="error" dismissible>{{error}}</v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3>Setup new account</h3>
          <div class="subheading">Step 2 of 3</div>
        </v-col>
      </v-row>


      <v-form v-model="valid" ref="form">

      <v-row>
        <v-col>
          <h3 class="text-left">Locations</h3>
        </v-col>
        <v-col class="text-right">
          <v-btn color="error" @click="onSave" :disabled="!valid" >
            Save
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="8">
          <v-text-field
            label="Location Name"
            solo
            hide-details="auto"   
            v-model="location.name"
            :rules="rules.name"    
            @keypress.enter="onSave"
          ></v-text-field>
        </v-col>
        <v-col sm="4">
          <v-text-field
            label="Maximum Units"
            solo
            hide-details="auto"   
            v-model="location.max_accounts"
            @keypress.enter="onSave"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="12">
          <v-textarea
            v-model="location.address"
            ref="address"
            label="Address"
            background-color="#fff"
            class="elevation-2"
            hide-details="auto"
            solo
          ></v-textarea>

        </v-col>
      </v-row>

      <v-row>
        <v-col sm="6">
          <v-text-field
            label="Sender Name"
            solo
            hide-details="auto"   
            v-model="location.email_name"
            :rules="rules.email_name"    
          ></v-text-field>
        </v-col>
        <v-col sm="6">
          <v-text-field
            label="Sender Email"
            solo
            hide-details="auto"   
            v-model="location.email_from"
            :rules="rules.email_from"
          ></v-text-field>
        </v-col>
      </v-row>


      <v-row>
        <v-col md="6" sm="12">
          <v-checkbox v-model="location.has_sms" label="Notify by SMS" :value="1"></v-checkbox>          
        </v-col>
      </v-row>

       <v-row v-if="location.has_sms">
          <v-col cols="12" md="6" sm="12">
              <v-select
                v-model="country"
                :items="['Canada', 'US']"
                label="Country"
                filled
                background-color="#fff"
                class="elevation-2 bg-white"
                hide-details="auto"
              ></v-select>
          </v-col>
          <v-col cols="12" md="6" sm="12">
              <v-select
                v-model="state"
                :items="states"
                label="State"
                filled
                background-color="#fff"
                class="elevation-2 bg-white"
                hide-details="auto"
                item-text="name"
                item-value="abbreviation"
                @change="onStateChange"
              ></v-select>
          </v-col>
      </v-row>

      <v-row v-if="location.has_sms">
        <v-col>
          <v-autocomplete
              v-model="city"
              :items="cities"
              color="white"
              hide-no-data
              hide-selected
              item-text="city"
              item-value="city"
              label="City"
              placeholder="Start typing to Search"
              filled
              @change="onCityChange"
              background-color="#fff"
              class="elevation-2 bg-white"
              hide-details="auto"

            ></v-autocomplete>          
        </v-col>
      </v-row>

      <v-row v-if="location.has_sms">


        <v-col sm="6">
          <v-select
            v-model="location.phone_number"
            :items="phoneNumbers"
            label="Twilio Number"
            filled
            background-color="#fff"
            class="elevation-2 bg-white"
            hide-details="auto"
          ></v-select>

        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col md="6" sm="12">
          <v-checkbox v-model="location.new_unit_enabled" label="Allow unit creation" :value="1"></v-checkbox>          
        </v-col>
      </v-row> -->

      </v-form>

      <v-row class="mt-7">
        <v-col>
          <v-data-table
            class="elevation-2"
            :items="locations"
            :headers="headers"
          >
            <template v-slot:[`item.max_accounts`]="{ item }">
              {{item.max_accounts ? item.max_accounts : 'Unlimited'}} 
            </template>

            <template v-slot:[`item.id`]="{ item }">
              <!-- <div class="text-right"> -->
              <v-btn small color="error"  class="mr-2" @click="onDelete(item)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>                            
              <!-- </div> -->
            </template>
          
          </v-data-table>
        </v-col>
      </v-row>

    </v-container>
  </v-main>
</template>
<script>
import AppBar from '@/components/general/AppBar.vue'
import Loading from '@/components/Loading.vue'
import {US_STATES, CANADA_STATES} from '../../../helpers/states'
export default {
  components: {
    AppBar,
    Loading
  },
  
  data:() => ({
    phoneNumbers: [],
    cities: [],
    country: null,
    state: null,
    city: null,
    canadaStates: CANADA_STATES,
    usStates: US_STATES,
    loading: false,
    headers: [
      {text: 'Location Name', value: 'name'},
      {text: 'No. of Units', value: 'max_accounts'},
      {text: 'Actions', value: 'id'}
    ],
    rules: {
      name: [v => !!v || 'Location Name is required'],
      max_accounts: [
        v => !!v || 'No. of Units is required',
        v => /^\d+$/.test(v) || 'Numbers only'
      ],
      email_name: [v => !!v || 'Sender Name is required'],
      email_from: [
        v => !!v || 'Sender Email is required',
        v => !v ? true : (/.+@.+/.test(v) || 'Email must be valid'),
      ],
    },
    valid: false,
    location: {
      id: null,
      name: null,
      max_accounts: null,
      email_name: null,
      email_from: null,
      new_unit_enabled: true,
      phone_number: null,
      has_sms: false,
      address: null
    },
    success: null,
    error: null
  }),

  computed: {
    buttons() {
      return [
        {label: 'Next Step', to: '/org/create/step3', color: 'primary', right: true, hidden: false, disabled: !this.locations.length}
      ]
    },
    locations() {
      return this.$store.state.org.org.locations
    },
    states() {
      if (!this.country) return []
      if (this.country === 'Canada') return CANADA_STATES
      return US_STATES
    },
    isValid() {
      if (!this.valid) return false
      if (this.location.has_sms) {
        if (!this.location.phone_number) return false
      }
      return this.valid
    }
  },

  mounted() {
  },

  methods: {
    onStateChange() {
      const data = {
        country: this.country,
        state: this.state,
      }
      this.loading = true
      this.success = null
      this.error = null
      this.$api.location.cities(data)
        .then((data) => {
          console.log(data)
          this.cities = data
        })
        .catch((msg) => {
          this.error = msg
        })
        .finally(() => {
          this.loading = false
        })
    },
    onCityChange() {
      const data = {
        country: this.country,
        state: this.state,
        city: this.city
      }
      this.loading = true
      this.success = null
      this.error = null
      this.$api.location.lookupPhoneNumbers(data)
        .then((data) => {
          console.log(data)
          this.phoneNumbers = data
        })
        .catch((msg) => {
          this.error = msg
        })
        .finally(() => {
          this.loading = false
        })
    },
    onDelete() {
      this.$store.commit('removeLocation', location)
    },
    onSave() {
      if (!this.valid) return
      const location = Object.assign({}, this.location)
      this.$store.commit('addLocation', location)
      this.$refs.form.reset()
      // this.rules = {
      //   name: [],
      //   max_accounts: [
      //   ],
      //   email_name: [],
      //   email_from: [
      //   ],

      // }

      // this.location = {
      //   id: null,
      //   name: '',
      //   max_accounts: null,
      //   email_name: '',
      //   email_from: '',
      //   new_unit_enabled: false,
      //   phone_number: null,
      //   has_sms: false
      // }

      // this.rules = {
      //   name: [v => !!v || 'Location Name is required'],
      //   max_accounts: [
      //     v => !!v || 'No. of Units is required',
      //     v => /^\d+$/.test(v) || 'Numbers only'
      //   ],
      //   email_name: [v => !!v || 'Sender Name is required'],
      //   email_from: [
      //     v => !!v || 'Sender Email is required',
      //     v => !v ? true : (/.+@.+/.test(v) || 'Email must be valid'),
      //   ],
      // }
    }
  }

}
</script>
