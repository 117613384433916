<template>
  <div>
      <v-row>
        <v-col>
          <h3 class="text-left">Staff</h3>
        </v-col>
        <v-col class="text-right">
          <v-btn color="error" @click="onSave" :disabled="!valid" >
            Save
          </v-btn>
        </v-col>
      </v-row>

      <v-form ref="form" v-model="valid">

      <v-row>
        <v-col>
          <v-text-field
            label="First Name"
            solo
            hide-details="auto"   
            v-model="user.first_name"
            :rules="rules.first_name"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Last Name"
            solo
            hide-details="auto"
            v-model="user.last_name"
            :rules="rules.last_name"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Email Address"
            solo
            hide-details="auto"
            v-model="user.email"
            :rules="rules.email"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select
            v-model="user.role"
            :items="roles"
            ref="rolesList"
            label="Role"
            filled
            background-color="#fff"
            class="elevation-2 bg-white"
            hide-details="auto"
            item-text="label"
            item-value="value"
            @change="onRoleChange"
            :rules="rules.role"
          ></v-select>                
        </v-col>
        <v-col>
          <v-checkbox v-if="user.role == 'MANAGER'" v-model="user.all_access" color="error" label="Unrestricted module access to all locations, current and future"></v-checkbox>
        </v-col>
      </v-row>

      </v-form>

      <v-row class="mt-2" v-if="(user.role == 'MANAGER' || user.role == 'DEFAULT') && !user.all_access">
        <v-col sm="12" md="6">
          <v-select
            :items="user.locations"
            ref="location"
            label="Location"
            filled
            class="elevation-2 bg-white"            
            background-color="#fff"
            hide-details="auto"
            item-text="name"
            item-value="name"
            return-object
            v-model="location"
          ></v-select>
        </v-col>
      </v-row>


      <v-row v-if="location && (user.role == 'MANAGER' || user.role == 'DEFAULT') && !user.all_access">
        <v-col cols="12">
          <!-- modules -->
          <v-card>
            <v-card-text class="text-left">
              <h3>General Modules <v-btn @click="toggleSelectedModules(location.modules)" class="float-right" right color="primary" small>{{modulesSelectAllLabel(location.modules)}}</v-btn></h3>
              <v-row>
                <v-col cols="12" md="2" sm="12" v-for="(mod, index) in MODULES" :key="index">
                  <v-checkbox @change="user.all_access = false" v-model="location.modules" :value="mod"  color="error" :label="mod == 'users' ? 'staff' : mod"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Accounts -->
          <v-card v-if="location.modules.includes('accounts')">
            <v-card-text class="text-left mt-4">
              <h3>Accounts Module <v-btn class="float-right" right color="primary" small @click="toggleSelectedAccountsActions(location.actions)">{{accountsSelectAllLabel(location.actions)}}</v-btn></h3>
              <v-row>                
                <v-col cols="12" md="2" sm="12" v-for="(action, index) in ACTIONS_ACCOUNTS" :key="index">
                  <v-checkbox @change="user.all_access = false" v-model="location.actions" :value="action" color="error" :label="actionLabel(action)"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Users -->
          <v-card v-if="location.modules.includes('users')">
            <v-card-text class="text-left mt-4">
              <h3>Staff Module <v-btn class="float-right" right color="primary" small @click="toggleSelectedUsersActions(location.actions)">{{usersSelectAllLabel(location.actions)}}</v-btn></h3>
              <v-row>                
                <v-col cols="12" md="2" sm="12" v-for="action in ACTIONS_USERS" :key="action">
                  <v-checkbox @change="user.all_access = false" v-model="location.actions" :value="action" color="error" :label="actionLabel(action)"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Locations -->
          <v-card v-if="location.modules.includes('locations')">
            <v-card-text class="text-left mt-4">
              <h3>Locations Module <v-btn class="float-right" right color="primary" small @click="toggleSelectedLocationsActions(location.actions)">{{locationsSelectAllLabel(location.actions)}}</v-btn></h3>
              <v-row>                
                <v-col cols="12" md="2" sm="12" v-for="action in ACTIONS_LOCATIONS" :key="action">
                  <v-checkbox @change="user.all_access = false" v-model="location.actions" :value="action" color="error" :label="actionLabel(action)"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Plans -->
          <v-card v-if="location.modules.includes('plans')">
            <v-card-text class="text-left mt-4">
              <h3>Plans Module <v-btn class="float-right" right color="primary" small @click="toggleSelectedPlansActions(location.actions)">{{plansSelectAllLabel(location.actions)}}</v-btn></h3>
              <v-row>                
                <v-col cols="12" md="2" sm="12" v-for="action in ACTIONS_PLANS" :key="action">
                  <v-checkbox @change="user.all_access = false"  v-model="location.actions" :value="action" color="error" :label="actionLabel(action)"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Email/SMS Templates -->
          <!-- <v-card v-if="location.modules.includes('emails') || location.modules.includes('sms')">
            <v-card-text class="text-left mt-4">              
              <v-row>
                <v-col cols="12" md="6" sm="12" v-if="location.modules.includes('emails')">
                  <h3>Email Templates Module</h3>
                  <v-checkbox @change="user.all_access = false" v-model="location.actions" value="emails.edit" color="error" label="Edit"></v-checkbox>
                </v-col>

                <v-col cols="12" md="6" sm="12" v-if="location.modules.includes('sms')">
                  <h3>SMS Templates Module</h3>
                  <v-checkbox @change="user.all_access = false" v-model="location.actions" value="sms.edit" color="error" label="Edit"></v-checkbox>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card> -->
          <!-- /Email/SMS Templates -->

        </v-col>

      </v-row>

  </div>
</template>
<script>
import {ACTIONS_ACCOUNTS, ACTIONS_USERS, ACTIONS_LOCATIONS, ACTIONS_PLANS, ACTIONS_EMAILS, ACTIONS_SMS, MODULES} from '../../../constants'
export default {
  data: () => ({
    MODULES,
    ACTIONS_ACCOUNTS,
    ACTIONS_USERS,
    ACTIONS_LOCATIONS,
    ACTIONS_PLANS,
    ACTIONS_EMAILS,
    ACTIONS_SMS,
    valid: false,
    user: {
      first_name: null,
      last_name: null,
      email: null,
      role: null,
      all_access: false,
      locations: []
    },
    isAdd: true,
    isEdit: false,
    roles: [
      {label: 'Super Administrator', value: 'SUPERADMIN'},
      {label: 'Administrator', value: 'ADMIN'},
      {label: 'Manager', value: 'MANAGER'},
      {label: 'Staff', value: 'DEFAULT'}
    ],
    location: null,
    org: null
  }),
  computed: {
    // locations() {
    //   // return this.$store.state.org.org.locations
    //   const locations = this.$store.state.org.org.locations
    //   locations.map(location => location.modules = this.MODULES.slice())
    //   locations.map(location => location.actions = [
    //     ...this.ACTIONS_ACCOUNTS,
    //     ...this.ACTIONS_USERS,
    //     ...this.ACTIONS_LOCATIONS,
    //     ...this.ACTIONS_PLANS,
    //     ...this.ACTIONS_EMAILS,
    //     ...this.ACTIONS_SMS
    //   ])
    //   return locations
    // },
    rules() {
      const rules = {
        first_name: [],
        last_name: [],
        email: [],
        role: []
      }

      rules.role.push(
        v => !!v || 'Role is required',
      )
      
      rules.first_name.push(
        v => !!v || 'First Name is required',
      )
      rules.last_name.push(
        v => !!v || 'Last Name is required',
      )
      rules.email.push(
        v => !!v || 'Email is required',
        v => !v ? true : (/.+@.+/.test(v) || 'Email must be valid'),
        v => this.checkUniqueEmail(v) ? true : 'Email address already used'
      )

      return rules
    },
  },
  mounted() {

      const locations = this.$store.state.org.org.locations
      locations.map(location => location.modules = this.MODULES.slice())
      locations.map(location => location.actions = [
        ...this.ACTIONS_ACCOUNTS,
        ...this.ACTIONS_USERS,
        ...this.ACTIONS_LOCATIONS,
        ...this.ACTIONS_PLANS,
        ...this.ACTIONS_EMAILS,
        ...this.ACTIONS_SMS
      ])
      this.user.locations = locations

    /*
    const locations = this.locations.slice()
    locations.map(location => location.modules = this.MODULES.slice())
    locations.map(location => location.actions = [
      ...this.ACTIONS_ACCOUNTS,
      ...this.ACTIONS_USERS,
      ...this.ACTIONS_LOCATIONS,
      ...this.ACTIONS_PLANS,
      ...this.ACTIONS_EMAILS,
      ...this.ACTIONS_SMS
    ])
    this.user.locations = locations
    */

  //  this.$api.org.profile(this.$route.params.orgId)
  //   .then((data) => {
  //     this.org = data
  //     this.user.locations = this.locations
  //   })
   
  },
  methods: {

    checkUniqueEmail(email) {
      if (email == this.$store.state.org.org.superadmin.email) return false
      const users = this.$store.state.org.org.users.filter(item => item.email == email)
      if (users.length) return false
      return true
    },
    
    onSave() {
      const user = Object.assign({}, this.user)
      this.$store.commit('addStaff', user)
      this.$refs.form.reset()
    },

    onRoleChange(val) {
      if (['SUPERADMIN', 'ADMIN'].includes(val)) {
        this.user.all_access = true
      } else if (val == 'DEFAULT') {
        this.user.all_access = false
      }
    },

    modulesSelectAllLabel(userModules) {
      return this.isAllModulesSelected(userModules) ? 'Unselect All' : 'Select All'
    },
    isAllModulesSelected(userModules) {
      let allSelected = true
      for (let i=0;i<this.MODULES.length;i++) {
        if (!userModules.includes(this.MODULES[i])) {
          allSelected = false
          break
        }
      }
      return allSelected
    },
    toggleSelectedModules(userModules) {
      if (this.isAllModulesSelected(userModules)) {
        console.log('Unselct all')
        this.location.modules.splice(0)
        // Unselect
        // for(let i=0;i<this.MODULES.length;i++) {
        //   let index = this.MODULES.findIndex((element) => element == this.MODULES[i])
        //   if (index > -1) {
        //     userModules.splice(index, 1)
        //   }
        // }
      } else {
        console.log('Select All')
        for(let i=0;i<this.MODULES.length;i++) {
          this.location.modules.push(this.MODULES[i])
        }
        // userModules = this.MODULES
        // Select
        // for (let i=0;i<this.MODULES.length;i++) {
        //   let index = userModules.findIndex((element) => element == this.MODULES[i])
        //   if (index == -1) {
        //     userModules.push(this.MODULES[i])
        //   }
        // }
      }
    },

    actionLabel(action) {
      if (action.includes('add')) return 'Add'
      if (action.includes('edit')) return 'Edit'
      if (action.includes('deactivate')) return 'Deactivate'
      if (action.includes('reactivate')) return 'Reactivate'
      if (action.includes('delete')) return 'Delete'
      return ''
    },
    // Accounts Select All Toggle
    accountsSelectAllLabel(userActions) {
      return this.isAllAccountsActionsSelected(userActions) ? 'Unselect All' : 'Select All'
    },
    isAllAccountsActionsSelected(userActions) {
      let allSelected = true
      for (let i=0;i<this.ACTIONS_ACCOUNTS.length;i++) {
        if (!userActions.includes(this.ACTIONS_ACCOUNTS[i])) {
          allSelected = false
          break
        }
      }
      return allSelected
    },
    toggleSelectedAccountsActions(userActions) {
      if (this.isAllAccountsActionsSelected(userActions)) {
        // Unselect
        for(let i=0;i<this.ACTIONS_ACCOUNTS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_ACCOUNTS[i])
          if (index > -1) {
            userActions.splice(index, 1)
          }
        }
      } else {
        // Select
        for (let i=0;i<this.ACTIONS_ACCOUNTS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_ACCOUNTS[i])
          if (index == -1) {
            userActions.push(this.ACTIONS_ACCOUNTS[i])
          }
        }
      }
    },
    // Users Select All Toggle
    usersSelectAllLabel(userActions) {
      return this.isAllUsersActionsSelected(userActions) ? 'Unselect All' : 'Select All'
    },
    isAllUsersActionsSelected(userActions) {
      let allSelected = true
      for (let i=0;i<this.ACTIONS_USERS.length;i++) {
        if (!userActions.includes(this.ACTIONS_USERS[i])) {
          allSelected = false
          break
        }
      }
      return allSelected
    },
    toggleSelectedUsersActions(userActions) {
      if (this.isAllUsersActionsSelected(userActions)) {
        // Unselect
        for(let i=0;i<this.ACTIONS_USERS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_USERS[i])
          if (index > -1) {
            userActions.splice(index, 1)
          }
        }
      } else {
        // Select
        for (let i=0;i<this.ACTIONS_USERS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_USERS[i])
          if (index == -1) {
            userActions.push(this.ACTIONS_USERS[i])
          }
        }
      }
    },
    // Locations Select All Toggle
    locationsSelectAllLabel(userActions) {
      return this.isAllLocationsActionsSelected(userActions) ? 'Unselect All' : 'Select All'
    },
    isAllLocationsActionsSelected(userActions) {
      let allSelected = true
      for (let i=0;i<this.ACTIONS_LOCATIONS.length;i++) {
        if (!userActions.includes(this.ACTIONS_LOCATIONS[i])) {
          allSelected = false
          break
        }
      }
      return allSelected
    },
    toggleSelectedLocationsActions(userActions) {
      if (this.isAllLocationsActionsSelected(userActions)) {
        // Unselect
        for(let i=0;i<this.ACTIONS_LOCATIONS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_LOCATIONS[i])
          if (index > -1) {
            userActions.splice(index, 1)
          }
        }
      } else {
        // Select
        for (let i=0;i<this.ACTIONS_LOCATIONS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_LOCATIONS[i])
          if (index == -1) {
            userActions.push(this.ACTIONS_LOCATIONS[i])
          }
        }
      }
    },
    // Plans Select All Toggle
    plansSelectAllLabel(userActions) {
      return this.isAllPlansActionsSelected(userActions) ? 'Unselect All' : 'Select All'
    },
    isAllPlansActionsSelected(userActions) {
      let allSelected = true
      for (let i=0;i<this.ACTIONS_PLANS.length;i++) {
        if (!userActions.includes(this.ACTIONS_PLANS[i])) {
          allSelected = false
          break
        }
      }
      return allSelected
    },
    toggleSelectedPlansActions(userActions) {
      if (this.isAllPlansActionsSelected(userActions)) {
        // Unselect
        for(let i=0;i<this.ACTIONS_PLANS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_PLANS[i])
          if (index > -1) {
            userActions.splice(index, 1)
          }
        }
      } else {
        // Select
        for (let i=0;i<this.ACTIONS_PLANS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_PLANS[i])
          if (index == -1) {
            userActions.push(this.ACTIONS_PLANS[i])
          }
        }
      }
    },


  }
}
</script>