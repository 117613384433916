<template>
  <v-main>
    <AppBar :buttons="buttons" />
    <Loading v-model="loading" />
    <v-container>

      <v-row>
        <v-col>
          <v-alert v-if="success" type="success" dismissible>{{success}}</v-alert>
          <v-alert v-if="error" type="error" dismissible>{{error}}</v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3>Setup new account</h3>
          <div class="subheading">Step 1 of 3</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3 class="text-left">Account Profile</h3>
        </v-col>
      </v-row>

      <v-form v-model="valid">

      <v-row>
        <v-col>
          <v-text-field
            label="Account Name"
            placeholder="Account Name"
            solo
            hide-details="auto"
            :rules="rules.name"
            v-model="name"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea
            solo
            label="Notes"
            v-model="note"
          ></v-textarea>          
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3 class="text-left">Primary Contact</h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            label="First Name"
            solo
            hide-details="auto"   
            :rules="rules.first_name"
            v-model="first_name"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Last Name"
            solo
            hide-details="auto"  
            :rules="rules.last_name" 
            v-model="last_name"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            label="Email Address"
            solo
            hide-details="auto"   
            :rules="rules.email"
            v-model="email"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Phone Number (Optional)"
            solo
            hide-details="auto"   
            v-model="phone"
          ></v-text-field>
        </v-col>
      </v-row>

      </v-form>

      <v-row class="mt-7">
        <v-col>
          <h3 class="text-left">Files</h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-file-input
            :value="files"
            chips
            solo
            label="File Upload"
            truncate-length="15"
            @change="onFileInput"
            multiple
          ></v-file-input>
        </v-col>
      </v-row>
      
    </v-container>
  </v-main>
</template>

<script>
import AppBar from '@/components/general/AppBar.vue'
import Loading from '@/components/Loading.vue'
import { mapState } from 'vuex'
export default {
  name: 'EmailTemplates',

  components: {
    AppBar,
    Loading,
  },

  data:() => ({
    valid: false,
    loading: false,
    success: null,
    error: null
  }),

  computed: {
    ...mapState(['org']),
    buttons() {
      return [
        {label: 'Next Step', to: '/org/create/step2', color: 'primary', right: true, hidden: false, disabled: !this.valid}
      ]
    },
    rules() {
      const rules = {
        first_name: [],
        last_name: [],
        email: [],
        name: []
      }

      rules.name.push(
        v => !!v || 'Account Name is required',
      )
      
      rules.first_name.push(
        v => !!v || 'First Name is required',
      )
      rules.last_name.push(
        v => !!v || 'Last Name is required',
      )
      rules.email.push(
        v => !!v || 'Email is required',
        v => !v ? true : (/.+@.+/.test(v) || 'Email must be valid'),
        v => this.checkUniqueEmail(v) ? true : 'Email address already exists'
      )

      return rules
    },
    name: {
      get() {
        return !this.$store.state.org.org ? null : this.$store.state.org.org.name
      },
      set(name) {
        this.$store.commit('updateOrg', {name})
      }
    },
    note: {
      get() {
        return !this.$store.state.org.org ? null : this.$store.state.org.org.note
      },
      set(note) {
        this.$store.commit('updateOrg', {note})
      }
    },
    first_name: {
      get() {
        return !this.$store.state.org.org.superadmin ? null : this.$store.state.org.org.superadmin.first_name
      },
      set(first_name) {
        this.$store.commit('updateSuperAdmin', {first_name})
      }
    },
    last_name: {
      get() {
        return !this.$store.state.org.org.superadmin ? null : this.$store.state.org.org.superadmin.last_name
      },
      set(last_name) {
        this.$store.commit('updateSuperAdmin', {last_name})
      }      
    },
    email: {
      get() {
        return !this.$store.state.org.org.superadmin ? null : this.$store.state.org.org.superadmin.email
      },
      set(email) {
        this.$store.commit('updateSuperAdmin', {email})
      }
    },
    phone: {
      get() {
        return !this.$store.state.org.org.superadmin ? null : this.$store.state.org.org.superadmin.phone
      },
      set(phone) {
        this.$store.commit('updateSuperAdmin', {phone})
      }
    },
    files() {
      return this.$store.state.org.org.files      
    }
  },

  mounted() {
    console.log(this.$route.query.new)
    if (typeof this.$route.query.new != 'undefined') {
      console.log('init')
      this.$store.commit('initNewOrg')
    }
  },

  methods: {
    onFileInput(files) {
      console.log('onFileInput', files)
      this.$store.commit('updateFiles', files)
    },
    async checkUniqueEmail(email) {
      try {
        await this.$api.user.checkUniqueEmail({email})
      } catch(e) {
        console.log('false')
        return false
      }
      return true
    }
  }
}
</script>
