var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('AppBar',{attrs:{"buttons":_vm.buttons,"back":"/org/create/step2"},on:{"onSave":_vm.onSave}}),_c('Loading',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_c('v-container',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Setup new account")]),_c('div',{staticClass:"subheading"},[_vm._v("Step 3 of 3")])])],1),_c('v-row',[_c('v-col',[(_vm.success)?_c('v-alert',{attrs:{"type":"success","dismissible":""}},[_vm._v(_vm._s(_vm.success))]):_vm._e(),(_vm.error)?_c('v-alert',{attrs:{"type":"error","dismissible":""}},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)],1),_c('user-profile'),_c('v-row',{staticClass:"mt-7"},[_c('v-col',[_c('h3',{staticClass:"text-left"},[_vm._v("Staff Added")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"items":_vm.users,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roleLabel(item.role))+" ")]}},{key:"item.locations",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.role == 'primary' ? _vm.locations.length : item.locations.length)+" ")]}},{key:"item.all_access",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"readonly":item.role == 'primary' ? true : false},model:{value:(item.all_access),callback:function ($$v) {_vm.$set(item, "all_access", $$v)},expression:"item.all_access"}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [(item.role != 'primary')?_c('div',[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.removeStaff(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1):_vm._e()]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }